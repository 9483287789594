/* eslint-disable import/no-default-export */
import { useEffect, useRef } from 'react'
import { useUppyEvent, useUppyState } from '@uppy/react'
import { FileAssetsDirectUploadFormProps } from './FileAssetsDirectUploadForm.types'
import { Button } from '../ui/Button'
import { FlexBox } from '../ui/FlexBox'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { acceptMap } from '../../pages/ProjectPage/ProjectPage.constants'

import styles from './FileAssetsDirectUploadForm.module.css'
import { useAddProjectAsset, useUppy } from './FileAssetsDirectUploadForm.hooks'
import FileUploadComponent from './FileUpload.component'

const FileAssetsDirectUploadForm: React.FC<FileAssetsDirectUploadFormProps> = ({
  onDismiss,
  type,
  allowedToDismiss,
  setAllowedToDismiss,
}) => {
  const uppy = useUppy(type)
  const { addProjectAsset } = useAddProjectAsset(type)
  const files = useUppyState(uppy, (state) => state.files)
  useUppyEvent(uppy, 'upload-success', async (file) => {
    if (file) {
      await addProjectAsset({ name: file.name! })
      uppy.setFileMeta(file.id, { assetProcessed: true })
    }
  })
  const fileInput = useRef<HTMLInputElement>(null)
  const { isMobile } = useDisplaySize()
  // @ts-ignore
  const accept = acceptMap[type]

  const handleChange = async (event: any) => {
    if (event.target.validity.valid) {
      for (let i = 0; i < event.target.files.length; i += 1) {
        uppy.addFile(event.target.files[i])
      }
    }
  }

  useEffect(() => {
    if (!Object.keys(files).length) {
      // Timeout here because modal must end its animation
      setTimeout(() => {
        fileInput?.current?.click()
      }, 500)
    }
  }, [files])

  useEffect(() => {
    const canDismiss = Object.keys(files).every(
      (fileId) =>
        (files[fileId].progress.uploadComplete || !!files[fileId].error) &&
        files[fileId].meta.assetProcessed,
    )
    setAllowedToDismiss(canDismiss)
  }, [files])

  return (
    <div className={styles.container}>
      <input
        className={styles.fileInput}
        ref={fileInput}
        onChange={handleChange}
        type="file"
        multiple
        name="files"
        accept={accept}
        data-testid="FileAssetsUploadForm"
      />
      {Object.keys(files).map((fileId) => (
        <FileUploadComponent key={fileId} file={files[fileId]} />
      ))}
      <div
        className={styles.buttonsContainer}
        style={{ height: isMobile && !files.length ? '108px' : '66px' }}
      >
        <FlexBox
          className={styles.buttonsWrapper}
          fullHeight
          fullWidth
          direction={isMobile ? 'column-reverse' : 'row'}
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          <Button color="light" onClick={onDismiss}>
            {allowedToDismiss ? 'Close' : 'Cancel'}
          </Button>
          {!Object.keys(files).length && (
            <Button
              color="primary"
              onClick={() => {
                fileInput?.current?.click()
              }}
            >
              Add Files
            </Button>
          )}
        </FlexBox>
      </div>
    </div>
  )
}

export default FileAssetsDirectUploadForm
