/* eslint-disable import/no-default-export */
import { IonChip, IonIcon, IonLabel, IonSpinner } from '@ionic/react'
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  ellipsisHorizontal,
  warning,
} from 'ionicons/icons'
import clsx from 'clsx'
import prettyBytes from 'pretty-bytes'
import { FlexBox } from '../ui/FlexBox'
import { FileUploadComponentProps } from './FileUpload.types'
import styles from './FileUpload.module.css'

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({ file }) => (
  <>
    <div className={styles.container} data-testid="FileUpload">
      <FlexBox
        className={styles.flexWrapper}
        direction="row"
        justifyContent="space-between"
        fullHeight
      >
        <div
          className={clsx(styles.fileName, {
            [styles.fileName__invalid]: !!file.error,
          })}
        >
          {file.name}
        </div>
        <FlexBox direction="row" justifyContent="space-between">
          <IonChip
            className={clsx(styles.fileSize, {
              [styles.fileSize__invalid]: !!file.error,
            })}
            outline
          >
            <IonLabel>{prettyBytes(file.size || 0)}</IonLabel>
          </IonChip>
          <FlexBox direction="row" justifyContent="flex-end">
            {file.progress.uploadStarted &&
              (!file.meta.assetProcessed || !file.progress.uploadComplete) &&
              !file.error && (
                <IonSpinner className={styles.spinner} name="circles" />
              )}
            {file.isPaused &&
              !file.progress.uploadComplete &&
              !file.meta.assetProcessed &&
              !file.error && (
                <IonIcon
                  className={styles.statusIcon}
                  color="primary"
                  icon={ellipsisHorizontal}
                />
              )}
            {((file.progress.uploadComplete && file.meta.assetProcessed) ||
              file.error) && (
              <IonIcon
                className={styles.statusIcon}
                color={!file.error ? 'primary' : 'syncred'}
                icon={!file.error ? checkmarkCircleOutline : closeCircleOutline}
              />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </div>
    {!!file.error && (
      <div className={styles.errorBox}>
        <FlexBox direction="row" justifyContent="flex-start" fullHeight>
          <IonIcon className={styles.errorIcon} icon={warning} />
          <div className={styles.errorMessage}>{file.error}</div>
        </FlexBox>
      </div>
    )}
  </>
)

export default FileUploadComponent
